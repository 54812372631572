<template>
  <div class="public">
    <div class="banner">
      <div class="content auto">
        <div class="title">微信公众号行业解决方案</div>
        <div class="sub">专业的营销推广服务，定制专属你的营销推广方案，帮助企业快速成长</div>
      </div>
    </div>
    <div class="system">
      <div class="auto">
        <div class="title">产品体系</div>
        <div class="items">
          <div class="item" v-for="(item, index) in systemItems" :key="index">
            <div class="iconbox">
              <div class="icon" :style="{ backgroundImage: `url(${item.icon})` }"></div>
            </div>
            <div class="con">
              <div class="name">{{ item.name }}</div>
              <div class="texts">
                <div class="text" v-for="(text, textindex) in item.texts" :key="textindex">{{ text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tool">
      <div class="auto">
        <div class="title">丰富的营销推广工具</div>
        <div class="box">
          <div class="left">
            <div class="tit">丰富的营销插件</div>
            <div class="subtit">推广+吸粉+客户粘性，精准营销，快速促成订单</div>
          </div>
          <div class="right">
            <div class="items">
              <div class="item" v-for="(item, index) in toolItems" :key="index">
                <div class="iconbox">
                  <div class="icon" :style="{ backgroundImage: `url(${item.icon})` }"></div>
                </div>
                <div class="con">
                  <div class="name">{{ item.name }}</div>
                  <div class="text">{{ item.text }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 行业 -->
    <div class="industry">
      <div class="auto">
        <div class="title">公众号覆盖行业广泛</div>
        <div class="items">
          <div class="item" v-for="(item, index) in industryItems" :key="index">
            <div class="iconbox">
              <div class="icon" :style="{ backgroundImage: `url(${item.icon})` }"></div>
            </div>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 搭建 -->
    <div class="build">
      <div class="auto">
        <div class="title">四步快速搭建你的公众号店铺</div>
        <div class="box">
          <div class="left">
            <div class="items">
              <div class="item">
                <div class="number">1</div>
                <div class="text">开店第一步，注册渡川账号</div>
              </div>
              <div class="item">
                <div class="number">2</div>
                <div class="text">前往微信公众平台注册公众号，并授权给渡川</div>
              </div>
              <div class="item">
                <div class="number">3</div>
                <div class="text">选择并应用店铺模板，装修你的小店</div>
              </div>
              <div class="item">
                <div class="number">4</div>
                <div class="text">发布并上架商品，开始经营</div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="bg"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      systemItems: [{
        icon: require("@/assets/images/publics/icon_yunying.svg"),
        name: '公众号运营',
        texts: ['为企业提供完善的代运营服务', '微信内容运营，微信活动运营']
      }, {
        icon: require("@/assets/images/publics/icon_xifen.svg"),
        name: '公众号吸粉',
        texts: ['真实活粉公众号增粉，超有效的小程序', '推广增粉方案帮助企业快速吸粉']
      }, {
        icon: require("@/assets/images/publics/icon_tuiguang.svg"),
        name: '企业产品推广',
        texts: ['定制企业专属微信推广方案，专业服务', '平台，提供产品推广服务']
      }, {
        icon: require("@/assets/images/publics/icon_pengyouquan.svg"),
        name: '企业产品推广',
        texts: ['定制企业专属微信推广方案，专业服务', '平台，提供产品推广服务']
      }, {
        icon: require("@/assets/images/publics/icon_guanggao.svg"),
        name: '企业产品推广',
        texts: ['定制企业专属微信推广方案，专业服务', '平台，提供产品推广服务']
      }, {
        icon: require("@/assets/images/publics/icon_fenxiao.svg"),
        name: '企业产品推广',
        texts: ['定制企业专属微信推广方案，专业服务', '平台，提供产品推广服务']
      }],
      toolItems: [{
        icon: require("@/assets/images/publics/icon_guaguaka.svg"),
        name: '刮刮卡',
        text: '即刮即兑，简单有趣'
      }, {
        icon: require("@/assets/images/publics/icon_qianghongbao.svg"),
        name: '抢红包',
        text: '圣诞老人到，点我抢红包'
      }, {
        icon: require("@/assets/images/publics/icon_chailihe.svg"),
        name: '拆礼盒',
        text: '帮拆礼盒，传播促裂变'
      }, {
        icon: require("@/assets/images/publics/icon_zhuanpan.svg"),
        name: '大转盘',
        text: '满目大奖，调动气氛'
      }, {
        icon: require("@/assets/images/publics/icon_zajindan.svg"),
        name: '砸金蛋',
        text: '即砸即兑，活跃粉丝'
      }, {
        icon: require("@/assets/images/publics/icon_denglong.svg"),
        name: '点灯笼',
        text: '点亮灯笼，赢取大奖'
      }, {
        icon: require("@/assets/images/publics/icon_kouhongji.svg"),
        name: '口红机',
        text: '经典口红机，拉新裂变'
      }, {
        icon: require("@/assets/images/publics/icon_more.svg"),
        name: '更多',
        text: '更多营销插件...'
      }],

      industryItems: [{
        icon: require("@/assets/images/publics/icon_shangcheng.svg"),
        name: '商城',
      }, {
        icon: require("@/assets/images/publics/icon_canyin.svg"),
        name: '餐饮',
      }, {
        icon: require("@/assets/images/publics/icon_meiye.svg"),
        name: '美业',
      }, {
        icon: require("@/assets/images/publics/icon_wuye.svg"),
        name: '物业',
      }, {
        icon: require("@/assets/images/publics/icon_fangyuan.svg"),
        name: '房源',
      }, {
        icon: require("@/assets/images/publics/icon_zulin.svg"),
        name: '租赁',
      }, {
        icon: require("@/assets/images/publics/icon_jiaoyu.svg"),
        name: '教育',
      }, {
        icon: require("@/assets/images/publics/icon_more.svg"),
        name: '更多',
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.public {
  .auto {
    max-width: 1200px;
    margin: 0 auto;
  }

  .banner {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    background: url(../../assets/images/publics/banner_1.png) center no-repeat;
    background-size: cover;

    .content {
      .title {
        text-align: center;
        font-size: 46px;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 36px;
      }

      .sub {
        text-align: center;
        font-size: 22px;
        font-weight: 600;
        color: #ffffff;
        line-height: 34px;
      }
    }
  }

  .system {
    padding: 60px 0;

    .title {
      text-align: center;
      font-size: 38px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 30px;
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .item {
        width: 340px;
        height: 226px;
        border-radius: 10px;
        background: linear-gradient(to bottom, #EAEFFE, rgba(255, 255, 255, 0.1));
        box-shadow: 0px 4px 12px 0px rgba(43, 94, 221, 0.2);
        position: relative;
        margin-top: 100px;

        .iconbox {
          width: 140px;
          height: 140px;
          background: #3D96FD;
          box-shadow: 0px 3px 10px 0px rgba(58, 104, 254, 0.38);
          border-radius: 20px;
          position: absolute;
          top: -70px;
          left: 50%;
          margin-left: -70px;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon {
            width: 74px;
            height: 74px;
            background-repeat: no-repeat;
            background-size: 74px;
            background-position: center;
          }
        }

        .con {
          padding-top: 104px;

          .name {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
          }

          .texts {
            margin-top: 15px;

            .text {
              text-align: center;
              font-weight: 400;
              color: #666666;
              line-height: 30px;
            }
          }
        }
      }
    }
  }

  .tool {
    padding: 60px 0;
    background-color: #FFFFFF;

    .title {
      text-align: center;
      font-size: 38px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 30px;
    }

    .box {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .left {
        width: 320px;
        margin-right: 50px;

        .tit {
          font-size: 38px;
          font-weight: 500;
        }

        .subtit {
          font-size: 18px;
          line-height: 26px;
          font-weight: 400;
          margin-top: 17px;
        }
      }

      .right {
        flex: 1;

        .items {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .item {
            width: 390px;
            height: 120px;
            background: #FFFFFF;
            box-shadow: 0px 3px 10px 0px rgba(175, 175, 175, 0.25);
            border-radius: 10px;
            margin-bottom: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .iconbox {
              width: 120px;
              height: 120px;
              background: #3D96FD;
              border-radius: 10px 0px 0px 10px;
              display: flex;
              justify-content: center;
              align-items: center;

              .icon {
                width: 52px;
                height: 52px;
                background-repeat: no-repeat;
                background-size: 52px;
                background-position: center;
              }
            }

            .con {
              flex: 1;
              padding: 0 38px;

              .name {
                font-size: 20px;
                font-weight: 500;
              }

              .text {
                font-size: 16px;
                font-weight: 400;
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }

  .industry {
    padding: 60px 0;

    .title {
      text-align: center;
      font-size: 38px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 30px;
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      .item {
        width: 150px;
        margin: 0 75px 40px 75px;

        .iconbox {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          background-color: #3D96FD;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            width: 52px;
            height: 52px;
            background-repeat: no-repeat;
            background-size: 52px;
            background-position: center;
          }
        }

        .name {
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          text-align: center;
          margin-top: 20px;
        }
      }
    }
  }

  // 快速搭建
  .build {
    padding: 60px 0;
    background-color: #ffffff;

    .title {
      text-align: center;
      font-size: 38px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 30px;
    }

    .box {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .left {
        .items {
          .item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 50px;

            .number {
              width: 50px;
              height: 50px;
              text-align: center;
              line-height: 50px;
              border-radius: 50%;
              color: #ffffff;
              font-size: 26px;
              font-weight: 600;
              background-color: #3D96FD;
            }

            .text {
              flex: 1;
              font-size: 22px;
              font-weight: 400;
              margin-left: 40px;
            }
          }
        }
      }

      .right {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .bg {
          width: 228px;
          height: 400px;
          background: url(../../assets/images/publics/bg_taotailang.png) center no-repeat;
          background-size: 100%;
          border-radius: 10px;
        }

      }
    }
  }
}
</style>